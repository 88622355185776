import * as yup from 'yup';

import cms from '../../../data/cms.json';
import dayjs from 'dayjs';

export const validationSchemaDefault = (hasIssueDate: boolean) =>
    yup.object({
        birthCountry: yup.string().required(cms.validations.REQUIRED_FIELD).nullable(),
        issuer: yup
            .string()
            .required(cms.validations.REQUIRED_FIELD)
            .min(2, 'Délka pole musí mít alespoň 2 znaky')
            .nullable(),
        citizenship: yup.string().required(cms.validations.REQUIRED_FIELD).nullable(),
        validateSecondCitizenship: yup.boolean(),
        secondCitizenship: yup
            .string()
            .when('validateSecondCitizenship', {
                is: true,
                then: (schema) => schema.required(cms.validations.REQUIRED_FIELD).nullable(),
            })
            .nullable(),
        birthplace: yup.string().required(cms.validations.REQUIRED_FIELD).nullable(),
        bankAccountNo: yup.string().required(cms.validations.REQUIRED_FIELD).nullable(),
        bankAccountCode: yup.string().required(cms.validations.REQUIRED_FIELD).nullable(),
        email: yup.string().email(cms.validations.EMAIL_FIELD).required(cms.validations.REQUIRED_FIELD),
        phone: yup.string().required(cms.validations.REQUIRED_FIELD),
        issueDate: !hasIssueDate
            ? yup
                  .string()
                  .required(cms.validations.REQUIRED_FIELD)
                  .test('yearValidation', cms.validations.REQUIRED_VALID_DATE_FIELD, (_, meta) => {
                      const yearString = meta.originalValue.toString().split(' ')[3];

                      const bottomThreshold = 2000;

                      return !(Number(yearString) < bottomThreshold) && !(Number(yearString).toString().length < 4);
                  })
                  .test('issueDateOverlap', cms.validations.ISSUE_DATE_GT_TODAY, (value) => {
                      return !dayjs(value).isAfter(new Date());
                  })
            : yup.string().notRequired(),
    });
